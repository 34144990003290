<template>
  <div>
    <!-- Sub Module Count Portion -->
    <h1>Super Admin Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: "SuperAdminDashboardView",
};
</script>

<style lang="scss">
.custom-card-statistics .statistics-body {
  padding: 0rem 2.4rem 1rem !important;
}
.lead-table {
  padding: 0px !important;
}
</style>
